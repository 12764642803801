<template>
<div class="obj-list wrapper ofh" v-if="!loading" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <h1> {{$t('all_users')}} </h1>
    </div>
    <div class="wp-body">
        <header class="wp-header w-full">
            <div class="grid grid-cols-12 gap-3 flex-1">
                <div class="col-span-3">
                    <el-select class="w-full" v-model="filterUser" clearable filterable remote :placeholder="`${$t('org_name')}`" :remote-method="remoteMethod" :loading="selLoading">
                            <el-option v-for="(item,i) in options" :key="i" :label="item.name_cyr" :value="item.id">
                            </el-option>
                        </el-select>
                </div>
                <div class="col-span-4 flex items-center gap-1">
                    <el-input class="w-full" style="margin-left: 0 !important;" :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()" clearable>
                        </el-input>
                        <el-button @click="__GET()" type="primary" icon="el-icon-search" class="ml-2" size="small" circle></el-button>
                </div>
            </div> 
            <button class="button dark-yellow mr-1" @click="addUsermodal=true"> <i class="el-icon-plus mr-1"></i> {{$t('add_new_user')}}</button>
        </header>
        <el-table :data="Users" class="mt-5" style="margin-bottom: 20px; width: 99%;" row-key="id">
            <el-table-column label="№" width="100" align="center">
                <template slot-scope="scope">
                    {{ (current_page - 1) * item_count + (scope.$index + 1) }}
                </template>
            </el-table-column>

            <el-table-column :label="$t('username')" width="300" sortable>
                <template slot-scope="scope">
                    <div>
                        <p class="fw-500 cp"> {{scope.row.login_name}} </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :prop="`organization.name_cyr`" :label="$t('organisation_name')" sortable>
            </el-table-column>
            <el-table-column align="center" width="110">
                <template slot-scope="scope">
                    <div class="d-flex justify-center">
                        <el-button size="small" @click="OpenMoadl(scope.row.id, scope.row.organization, scope.row.login_name, scope.row.role)" class="el-icon-unlock el-button-download" plain></el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="item_count" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
    </div>
    <div class="user-add-modal" v-if="modal">
        <div class="user-add-cover"></div>
        <div class="user-add-modal-content">
            <div class="w-full" v-show="step==1">
                <div class="user-modal-body">
                    <h1>{{$t('change')}}</h1>
                    <el-form :model="User" ref="edituser" :rules="UserRules">
                        <div class="item">
                            <el-form-item prop="login_name">
                                <p>{{$t('login')}}</p>
                                <el-input placeholder="Username" v-model="User.login_name" class="input-with-select" :disabled="modalLoading || modalLoadinglogin">
                                    <el-button slot="append" type="button" @click="GenerateLogin" :icon="modalLoadinglogin?'el-icon-loading':'el-icon-refresh'" :disabled="modalLoading ||modalLoadinglogin"></el-button>
                                </el-input>
                            </el-form-item>
                        </div>

                        <div class="item">
                            <el-form-item prop="password">
                                <p>{{$t('password')}}</p>
                                <el-input placeholder="Password" v-model="User.password" class="input-with-select">
                                    <el-button slot="append" @click="Generate" type="button" :icon="modalLoading?'el-icon-loading':'el-icon-refresh'" :disabled="modalLoading"></el-button>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item>
                                <p>{{ $t('role') }}</p>
                                <template>
                                    <el-radio v-for="(item,i) in roles" :key="i" :id="`role_name`" :disabled="modalLoading" v-model="User.role" class="prim" :label="item.id" border>
                                        {{item.name=='operator'? 'Оператор':'ТИ Оператор'}}
                                    </el-radio>

                                </template>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <div class="flex justify-end gap-2 mt-8">
                    <button type="button" class="button black" @click="Clear" :disabled="modalLoading"> {{$t('otmen')}} </button>
                    <button type="button" class="button dark-blue" @click="UserSave" :disabled="modalLoading"> <span v-if="!modalLoading">{{ $t('save') }}</span> <i v-else class="el-icon-loading"></i> </button>
                </div>
            </div>
            <div class="w-full" v-show="step==2">
                <div class="user-modal-body">
                    <h2> <i class="el-icon-success"></i></h2>
                    <h1>{{$t('create_user_success')}}</h1>
                    <div class="user-item-list">
                        <div class="user-data">
                            <p class="user-info-title">{{$t('login')}}:</p>
                            <p> {{User.login_name}}</p>
                            <button @click="Copy(User.login_name )"><i class="el-icon-document-copy"></i> </button>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title"> {{$t('password')}}: </p>
                            <p> {{User.password}}</p>
                            <button @click="Copy(User.password)"> <i class="el-icon-document-copy"></i> </button>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title">{{$t('role')}}:</p>
                            <p> {{roleNames[getRoleName(User.role)]}}</p>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title"> {{$t('organisation_name')}}:</p>
                            <p>{{organization_name}}</p>
                        </div>
                    </div>

                </div>
                <div class="flex justify-end gap-2 mt-8">
                    <button type="button" class="button black" @click="CopyAll()"> <i class="el-icon-document-copy"></i> </button>
                    <button type="button" class="button dark-blue" @click="Clear"> {{$t('close')}} </button>
                </div>
            </div>
        </div>
    </div>
    <div class="user-add-modal" v-if="addUsermodal">
        <div class="user-add-cover"></div>
        <div class="user-add-modal-content">
            <div class="w-full" v-show="step==1">
                <div class="user-modal-body">
                    <h1>{{$t('create_new_user')}}</h1>
                    <el-form :model="User" ref="userAdd" :rules="UserRules">

                        <div class="item">
                            <el-form-item>
                                <p>{{$t('org_name')}}</p>
                                <el-select class="w-full" v-model="organization" filterable remote :placeholder="`*${$t('org_name')}`" :remote-method="remoteMethod" :loading="selLoading">
                                    <el-option v-for="(item,i) in options" :key="i" :label="item.name_cyr" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item>
                                <p>{{ $t('role') }}</p>
                                <template>
                                    <el-radio v-for="(item,i) in roles" :key="i" :id="`role_name`" :disabled="modalLoading" v-model="User.role" class="prim" :label="item.id" border>
                                      {{$t(keyTitles[item.name])}}
                                    </el-radio>

                                </template>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item prop="login_name">
                                <p>{{$t('login')}}</p>
                                <el-input :placeholder="`*${$t('login')}`" v-model="User.login_name" class="input-with-select" :disabled="seldis || modalLoading || modalLoadinglogin">
                                    <el-button slot="append" type="button" @click="GenerateLogin" :icon="modalLoadinglogin?'el-icon-loading':'el-icon-refresh'" :disabled="seldis || modalLoading ||modalLoadinglogin"></el-button>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item prop="password">
                                <p>{{$t('password')}}</p>
                                <el-input :placeholder="`*${$t('password')}`" v-model="User.password" class="input-with-select" :disabled="seldis || modalLoading">
                                    <el-button slot="append" @click="Generate" type="button" :icon="modalLoading?'el-icon-loading':'el-icon-refresh'" :disabled="seldis || modalLoading"></el-button>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <div class="flex justify-end gap-2 mt-8">
                    <button type="button" class="button black" @click="Clear" :disabled="modalLoading"> <i class="el-icon-close mr-1"></i>{{$t('otmen')}} </button>
                    <button type="button" class="button dark-blue" @click="UserSave2" :disabled="seldis || modalLoading" :class="seldis || modalLoading?'disabled-button':''"> <span v-if="!modalLoading"><i class="el-icon-check mr-1"></i>{{ $t('save') }}</span> <i v-else class="el-icon-loading"></i> </button>
                </div>
            </div>
            <div class="w-full" v-show="step==2">
                <div class="user-modal-body">
                    <h2> <i class="el-icon-success"></i></h2>
                    <h1>{{$t('create_user_success')}}</h1>
                    <div class="user-item-list">
                        <div class="user-data">
                            <p class="user-info-title">{{$t('login')}}:</p>
                            <p> {{User.login_name}}</p>
                            <button @click="Copy(User.login_name )"><i class="el-icon-document-copy"></i> </button>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title"> {{$t('password')}}: </p>
                            <p> {{User.password}}</p>
                            <button @click="Copy(User.password)"> <i class="el-icon-document-copy"></i> </button>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title">{{$t('role')}}:</p>
                            <p> {{roleNames[getRoleName(User.role)]}}</p>
                        </div>
                        <div class="user-data">
                            <p class="user-info-title"> {{$t('organisation_name')}}:</p>
                            <p>{{organization_name}}</p>
                        </div>
                    </div>

                </div>
                <div class="flex justify-end gap-2 mt-8">
                    <button type="button" class="button black" @click="CopyAll()"> <i class="el-icon-document-copy"></i> </button>
                    <button type="button" class="button dark-blue" @click="Clear"> {{$t('close')}} </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ScletonPage header="true" v-else />
</template>

<script>
import ScletonPage from '../../components/Scletons/Page.vue'
import GeneratedPassword from '../../components/PasswordGenerate.vue'
import GenerateLogin from '../../components/LoginGenerate.vue'
import axios from 'axios';
export default {
    components: {
        ScletonPage,
        GeneratedPassword,
        GenerateLogin
    },
    data() {
        return {
            page_count: 1,
            current_page: 1,
            current_page1: 1,
            isAdmin: false,
            loading: false,
            item_count: 10,
            input2: null,
            Users: [],
            search: "",
            modal: false,
            modalLoadinglogin: false,
            step: 1,
            user_id: 0,
            addUsermodal: false,
            filterUser: '',
            User: {
                login_name: "",
                password: "",
                role: ''
            },
            UserRules: {
                login_name: [{
                        required: true,
                        message: 'Илтимос фойдаланувчи номини киритинг!',
                        trigger: 'change'
                    },
                    {
                        min: 2,
                        max: 25,
                        message: 'Логин узунлиги 2 ва 25 орасида бўлиши керак!',
                        trigger: 'change'
                    }
                ],
                password: [{
                        required: true,
                        message: 'Илтимос паролни киритинг!',
                        trigger: 'change'
                    },
                    {
                        min: 8,
                        max: 20,
                        message: 'Парол узунлиги 8 ва 25 орасида бўлиши керак!',
                        trigger: 'change'
                    }
                ],
            },
            organization: '',
            modalLoading: false,
            options: [],
            selLoading: false,
            seldis: true,
            organization_name: 'Organisation Name',
            roles: [],
            roleNames: {
                tf_operator: 'ТИ Оператор',
                operator: 'Оператор',
                no: '--'
            },
            keyTitles: {
              operator: 'operator',
              tf_operator: 'tf_operator',
              org_admin: 'org_admin',
            }
        };
    },
    watch: {
        organization(val) {
            if (val !== '') {
                this.seldis = false
                this.User.organization = val
                this.User.role = this.roles[0].id ? this.roles[0].id : 3
                this.organization_name = this.options.filter(item => {
                    return item.id == val
                })
                this.organization_name = this.organization_name[0] ? this.organization_name[0] : '*'
                this.Generate()
                this.GenerateLogin()
            }
        },
        filterUser() {
            this.__GET()
        }
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        role() {
            return this.$store.state.role;
        }
    },
    mounted() {
        this.loading = true
        this.current_page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
        this.search = this.$route.query.search ? this.$route.query.search : ''
        this.item_count = this.$route.query.item_count ? this.$route.query.item_count : 10
        this.__GET();
        this._Getroles()
        axios.get(`/organization/?limit=10`)
            .then(res => {
                this.options = res.data.results
            })

    },
    methods: {
        paginate(val) {
            this.current_page = val;
            this.__GET();
        },
        paginateSize(val) {
            this.item_count = val
            this.__GET();
        },
        __GET() {
            this.loading = true;
            let offset = this.item_count * (this.current_page - 1);

            if (this.$route.query.id) {
                this.isAdmin = true;
                if (this.search != '' || this.item_count != 10) {
                    this.$router.push(`/users/?id=${this.$route.query.id}&page=${this.current_page}&item_count=${this.item_count}&search=${this.search}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                } else {
                    this.$router.push(`/users/?id=${this.$route.query.id}&page=${this.current_page}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                }
                axios.get(`/users/?organization=${this.filterUser}&search=${this.search}&limit=${this.item_count}&offset=${offset}`)
                    .then((response) => {
                        this.Users = response.data.results;
                        this.page_count = Math.ceil(response.data.count / this.item_count);
                        this.current_page1 = this.$route.query.page ? parseInt(this.$route.query.page) : 1
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                if (this.search != '' || this.item_count != 10) {
                    this.$router.push(`/users/?page=${this.current_page}&item_count=${this.item_count}&search=${this.search}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                } else {
                    this.$router.push(`/users/?page=${this.current_page}`)
                        .catch(e => {
                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                console.log(e)
                            }
                        });
                }

                axios.get(`/users/?organization=${this.filterUser}&search=${this.search}&limit=${this.item_count}&offset=${offset}`)
                    .then((response) => {
                        this.Users = response.data.results;
                        this.page_count = Math.ceil(response.data.count / this.item_count);
                        this.current_page1 = this.$route.query.page ? parseInt(this.$route.query.page) : 1

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        remoteMethod(query) {
            if (query !== '') {
                this.selLoading = true
                axios.get(`/organization/?search=${query}`)
                    .then((response) => {
                        this.options = response.data.results.length > 0 ? response.data.results : []
                    })
                    .catch(() => {
                        this.options = []
                    })
                    .finally(() => {
                        this.selLoading = false;
                    })
            } else {
                this.options = [];
            }
        },
        OpenMoadl(id, name, username, role) {
            this.modal = true
            this.organization_name = name
            this.User.login_name = username
            this.User.role = role.id
            this.user_id = id
        },
        Clear() {
            this.step = 1
            this.organization_name = '',
                this.User = {
                    login_name: "",
                    password: "",
                    role: this.roles[0].id
                }
            this.organization = ''
            if(this.$refs.userAdd){
                this.$refs.userAdd.resetFields()
            }
            if(this.$refs.edituser){
                this.$refs.edituser.resetFields()
            }


            this.User.password = ''
            this.modal = false
            this.user_id = 0
            this.addUsermodal = false
        },
        UserSave() {
            this.$refs.edituser.validate(valid => {
                if (valid) {
                    this.modalLoading = true
                    axios.patch(`/users/${this.user_id}/`, this.User)
                        .then(response => {
                            this.modal = false
                            this.step = 2
                            this.organization_name = this.organization_name && this.organization_name != null && this.organization_name.name_cyr ? this.organization_name.name_cyr : '***'
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('create_user_success'),
                                timer: 3000
                            }).then(() => {
                                this.modal = true
                            })

                        })
                        .finally(() => {
                            this.modalLoading = false
                        })
                }
            })

        },
        CopyAll() {
            var item = `Логин: ${this.User.login_name}, \nПароль: ${this.User.password}, \nТашкилот: ${this.organization_name}`
            var a = this.$copyText(item)
            a ? this.$swal.fire({
                icon: 'success',
                title: this.$t('copy_success'),
                timer: 3000
            }) : this.$swal.fire({
                icon: 'error',
                title: this.$t('error_ser'),
                timer: 3000
            })
            this.Clear()
        },
        Copy(item) {
            var a = this.$copyText(item)
            a ? this.$swal.fire({
                icon: 'success',
                title: this.$t('copy_success'),
                timer: 3000
            }) : this.$swal.fire({
                icon: 'error',
                title: this.$t('error_ser'),
                timer: 3000
            })
        },
        Generate() {
            let charactersArray = 'a-z'.split(',')
            let CharacterSet = ''
            let password = ''
            let size = 12
            charactersArray = 'a-z,A-Z,0-9,#'.split(',')
            if (charactersArray.indexOf('a-z') >= 0) {
                CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
            }
            if (charactersArray.indexOf('A-Z') >= 0) {
                CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            }

            if (charactersArray.indexOf('0-9') >= 0) {
                CharacterSet += '0123456789'
            }
            if (charactersArray.indexOf('#') >= 0) {
                CharacterSet += '![]{}()%&*$#^<>~@|'
            }
            for (let i = 0; i < size; i++) {
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
            }
            this.User.password = password
        },
        GenerateLogin() {
            let charactersArray = 'a-z'.split(',')
            let CharacterSet = ''
            let user = ''
            let size = 4
            if (charactersArray.indexOf('a-z') >= 0) {
                CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
            }
            if (charactersArray.indexOf('A-Z') >= 0) {
                CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            }

            if (charactersArray.indexOf('0-9') >= 0) {
                CharacterSet += '0123456789'
            }
            if (charactersArray.indexOf('#') >= 0) {
                CharacterSet += '![]{}()%&*$#^<>~@|'
            }
            for (let i = 0; i < size; i++) {
                user += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
            }
            var id = this.organization_name && this.organization_name != null && this.organization_name.id ? this.organization_name.id : ''
            user = 'user_' + id + user

            this.modalLoadinglogin = true

            axios.get(`/users?search=${user}`)
                .then(res => {
                    if (res.data.results.length < 1) {
                        this.User.login_name = user
                        this.modalLoadinglogin = false
                    } else {
                        this.GenerateLogin()
                    }
                })
                .catch(() => {
                    this.modalLoadinglogin = false
                })
        },
        UserSave2() {
            this.modalLoading = true
            axios.get(`/users?search=${this.User.login_name}`)
                .then((res) => {
                    if (res.data.results.length < 1) {
                        axios.post('/users/', this.User)
                            .then(response => {
                                this.modal = false
                                this.step = 2
                                this.organization_name = this.organization_name.name_cyr
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('create_user_success'),
                                    timer: 3000
                                }).then(() => {
                                    this.modal = true
                                })

                            })
                            .finally(() => {
                                this.modalLoading = false
                            })
                    } else {
                        this.modalLoading = false
                    }
                })

        },
        _Getroles() {
            axios.get('/role/views')
                .then(res => {
                    this.roles = res.data
                    this.User.role = res.data[0].id
                })
        },
        getRoleName(id) {
            let a = 'no'
            this.roles.forEach(element => {
                if (element.id == id) {
                    a = element.name
                }
            })
            return a
        }
    },
};
</script>

<style lang="scss" scoped>

.disabled-button {
    opacity: 0.8;
    cursor: not-allowed !important;
}

.user-add-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-add-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        z-index: 0;
    }

    .user-add-modal-content {
        max-width: 700px;
        width: 100%;
        background: #fff;
        z-index: 1;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        padding: 30px;
    }

    .user-add-modal-header {
        h1 {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .user-modal-body {
        .el-radio__label{
          padding-left: 2px;
        }
        h1 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;
        }

        h2 {
            font-size: 80px;
            text-align: center;
            color: green;
        }

        .item {
            p {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 5px;
            }

            .el-form-item {
                margin-bottom: 15px !important;
            }
        }

    } 

    .user-data {
        display: flex;
        align-items: center;
        padding: 5px 0px;

        .user-info-title {
            margin-right: 10px;
            min-width: 120px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
        }

        button {
            margin-left: 10px;
            font-size: 14px;
            width: 30px;
            height: 30px;
        }
    }

}
</style>
